import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Deload Week`}</strong></p>
    <p>{`5x300m Ski Erg with 1:00 rest between sets`}</p>
    <p>{`then,`}</p>
    <p>{`10-Rope Climbs to 15′`}</p>
    <p>{`100-Single Arm DB OHS (20/15`}{`#`}{`)`}</p>
    <p>{`200-Double Unders`}</p>
    <p>{`400-Double Arm Battle Rope Waves`}</p>
    <p><em parentName="p">{`*`}{`partitioned any way you’d like`}</em></p>
    <p>{`then,`}</p>
    <p>{`Snatch/OHS mobility work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      